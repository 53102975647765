import {getQueryParam} from 'src/utils/url';

class PostMessage {
  constructor() {
    this.sendReadyMessage = this.sendReadyMessage.bind(this);
    this.handleLabLinkClick = this.handleLabLinkClick.bind(this);
    this.handleQtyToggle = this.handleQtyToggle.bind(this);
    this.postMessage = this.postMessage.bind(this);

    document.addEventListener('DOMContentLoaded', this.sendReadyMessage);
    $(document).on(
      'click',
      'html.is-pcFramed a.is-pcLabLink, html.is-iFramed a.is-pcLabLink',
      this.handleLabLinkClick
    );
    $(document).on(
      'change.style',
      'html.is-pcFramed #pc-Style-colors-qtyToggle, html.is-pcFramed #pc-ToolBar-toggle-2',
      this.handleQtyToggle
    );
  }

  sendReadyMessage() {
    this.postMessage('domReady');
  }

  handleLabLinkClick(event) {
    event.preventDefault();
    const ndxStyleId = $('.pc-Style').data('styleno');
    const labLinkUrl = $(event.target).attr('href');
    const ndxColorId = getQueryParam('PK', labLinkUrl);
    const ndxKeyword = getQueryParam('KW', labLinkUrl);
    const context = $(event.target).parents('.pc-Show, .pc-Style, body');
    const $catElem = $('meta[property=categoryinfo]');
    if ($catElem.length) {
      var catId = parseInt($catElem.attr('id'));
      var catName = $catElem.attr('name');
      var catPath = $catElem.attr('path').split('?')[0];
    }
    const swatchEl = context.find('.pc-Swatch.is-selected');
    const colorNo = swatchEl.attr('data-colorid');
    const styleNo = swatchEl.attr('data-styleid');
    const styleName = swatchEl.attr('data-style-name');
    const minQty = swatchEl.attr('data-min-qty');
    const preferSinglesToggle = document.querySelector(
      '.pc-ToolBar-toggle-min-qty'
    );
    const preferSingles =
      preferSinglesToggle && parseInt(minQty) < 6
        ? preferSinglesToggle.checked
        : false;

    const productInfo = {
      name: swatchEl.attr('data-name'),
      productId: colorNo,
      sizes: swatchEl.attr('data-size-list'),
      size_range: swatchEl.attr('data-size-range'),
      decoration: swatchEl.attr('data-decoration'),
      minQty: swatchEl.attr('data-min-qty'),
      defaultCategoryId: swatchEl.attr('data-primary-category-id'),
      styleName
    };
    const payload = {
      styleId: ndxStyleId,
      colorId: ndxColorId,
      categoryPath: catPath,
      event: 'catalog:product-selected',
      data: [colorNo, productInfo, styleNo, styleName, catId, catName],
      keywords: ndxKeyword,
      preferSingles
    };
    this.postMessage(payload);
  }

  handleQtyToggle({currentTarget}) {
    const payload = {
      preferSingles: currentTarget.checked
    };
    this.postMessage(payload);
  }

  postMessage(payload) {
    if (__ENV__ === 'production') {
      if (document.referrer.includes('customink.com')) {
        window.parent.postMessage(payload, '*');
      }
    } else {
      window.parent.postMessage(payload, '*');
    }
  }
}

ProductCatalog.PostMessage = new PostMessage();
