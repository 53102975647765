import jQuery from 'jquery';
global.$ = jQuery;
global.jQuery = jQuery;
// Used by toolbar and a few other JS components
const Waypoint = require('waypoints/lib/jquery.waypoints');
require('intersection-observer');
require('url-polyfill');
import 'src/product_catalog';
import 'src/shared/analytics';
import 'src/shared/iframe';
import 'src/shared/gtm';
import 'src/shared/image_lazy_loader';
import 'src/shared/toolbar';
import 'src/shared/toolbar/shopby';
import 'src/shared/toolbar/search';
import 'src/shared/ujspopup';
import 'src/shared/favorites';
import 'src/shared/faqs';
import 'src/shared/fullstory_identify';
import 'src/shared/fullstory';
import 'src/shared/overlay';
import 'src/shared/overlay_dropdown';
import 'src/shared/post_message';
import 'src/shared/local_storage';
