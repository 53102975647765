import {getQueryParam} from 'src/utils/url';

class IFrame {
  constructor() {
    this.setBodyStyleAttributes = this.setBodyStyleAttributes.bind(this);
    this.prefix = this.prefix.bind(this);
    document.addEventListener('ready', this.setBodyStyleAttributes);
  }

  setBodyStyleAttributes() {
    if (!this.isFramed()) {
      return;
    }
    return $('body').css({
      height: '100%',
      overflow: 'auto',
      '-webkit-overflow-scrolling': 'touch'
    });
  }

  isFramed() {
    return this.iframer() !== null;
  }

  iframer() {
    return getQueryParam('iframer');
  }

  isIframer(name) {
    return this.iframer() === name;
  }

  prefix(msg) {
    if (!this.isFramed()) {
      return msg;
    }
    return this.iframer() + msg;
  }
}

ProductCatalog.IFrame = new IFrame();

jQuery(() =>
  document.addEventListener(
    'ready',
    ProductCatalog.IFrame.setBodyStyleAttributes()
  )
);
