class Analytics {
  constructor() {
    this.addTagEventListeners();
  }

  // Private

  addTagEventListeners() {
    $(document).on(
      'click',
      '.pc-BreadcrumbItem .pc-Breadcrumb',
      (e) => { this.clickBreadcrumb(e); }
    );
  }

  // Private: Event Handlers

  clickBreadcrumb(evt) {
    CustomInk.Metrics.fireEvent({
      category: 'catalog breadcrumbs',
      action: __PAGE_NAME__ || '/catalog',
      label: evt.currentTarget.getAttribute('href'),
      interaction: true
    });
    return true;
  }
}

jQuery(() => (ProductCatalog.Analytics = new Analytics()));
