$(document).on('click', 'a[rel=popup]', function(e) {
  e.preventDefault();
  const link = $(this);
  const url = link.attr('href');
  const name = link.attr('data-popup-name');
  const options = link.attr('data-popup-options');
  if (!window.name || window.name.length === 0) {
    window.name = 'customink-catalog';
  }
  window.open(url, name, options);
});
