class OverlayDropdown {
  constructor() {
    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
    this.closeAll = this.closeAll.bind(this);
    this.globalClose = this.globalClose.bind(this);
    this.initBehavior();
  }

  isOpen() {
    return $('.pc-OverlayDropdown-headerClose:visible').length > 0;
  }

  selfClicked(e) {
    return $(e.target).parents('.pc-OverlayDropdown').length;
  }

  open(e, el) {
    if (this.selfClicked(e)) {
      return;
    }
    return $(el).toggle();
  }

  close(e) {
    return $(e.target)
      .parents('.pc-OverlayDropdown')
      .hide();
  }

  closeAll() {
    return $('.pc-OverlayDropdown').hide();
  }

  // Private

  initBehavior() {
    $(document).bind('pcOverlayDropdown.open', this.open);
    $(document).on('click', '.pc-OverlayDropdown-headerClose', this.close);
    $(document).on('click', 'body', this.globalClose);
    return window.addEventListener('popstate', this.closeAll);
  }

  globalClose(e) {
    if (this.selfClicked(e)) {
      return;
    }
    return this.closeAll();
  }
}

jQuery(() => (ProductCatalog.OverlayDropdown = new OverlayDropdown()));
