class Toolbar {
  constructor() {
    this.waypointHandlerToolbar = this.waypointHandlerToolbar.bind(this);
    this.waypointHandlerFooter = this.waypointHandlerFooter.bind(this);
    this.waypointRefreshFooter = this.waypointRefreshFooter.bind(this);
    this.registerWaypoint();
    this.initBehavior();
  }

  reConstructor() {
    Waypoint.destroyAll();
    this.registerWaypoint();
  }

  el() {
    return $('.pc-ToolBar');
  }

  parent() {
    return this.el().parent();
  }

  next() {
    if ($('.pc-Header').length) {
      return $('.pc-Header').next();
    } else {
      if ($('.pc-Styles-selectedFilters').length) {
        return $('.pc-Styles-selectedFilters').next();
      } else {
        return $('.pc-ToolBar').next();
      }
    }
  }

  height() {
    return this._height || (this._height = this.el().height());
  }

  // Private

  initBehavior() {
    $(document).on(
      'click',
      '.pc-OverlayDropdown-link',
      this.overlayDropdownLink
    );
  }

  registerWaypoint() {
    const toolbar = this.el();
    if (!toolbar.length) {
      return;
    }
    // Only register waypoint for non algolia listing pages
    if ($('.pc-Categories, .pc-Subcategories, .pc-Style, .algolia-listing-pages').length) {
      return;
    }
    this.stickyToolbar = new Waypoint({
      element: toolbar.get(0),
      offset: 0,
      handler: this.waypointHandlerToolbar
    });
    return (this.stickyFooter = new Waypoint({
      element: $('.sb-Footer').get(0),
      offset: 100,
      handler: this.waypointHandlerFooter
    }));
  }

  // Private (event handlers)

  overlayDropdownLink(e) {
    let $link = $(e.target);
    if (!$link.is('.pc-OverlayDropdown-link')) {
      $link = $link.parents('.pc-OverlayDropdown-link');
    }
    const radio = $link.siblings('input[type=radio]').first();
    $(radio).prop('checked', !$(radio).is(':checked'));
  }

  waypointHandlerToolbar(direction) {
    if (this.el().hasClass('is-static')) {
      return;
    }
    if (ProductCatalog.IFrame.isFramed() && this.flag === 'test') {
      return;
    }
    if (direction === 'down') {
      this.el().addClass('is-sticky');
      return this.parent().css('padding-top', `${this.height()}px`);
    } else {
      this.parent().css('padding-top', '0');
      return this.el().removeClass('is-sticky');
    }
  }

  waypointHandlerFooter(direction) {
    if (this.el().hasClass('is-static')) {
      return;
    }
    if (direction === 'down') {
      this.el().removeClass('is-sticky');
      return this.parent().css('padding-top', '0');
    } else {
      this.el().addClass('is-sticky');
      return this.parent().css('padding-top', `${this.height()}px`);
    }
  }

  waypointRefreshFooter() {
    Waypoint.Context.findByElement(window).refresh();
  }
}

jQuery(() => (ProductCatalog.Toolbar = new Toolbar()));
