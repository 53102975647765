import { trackFAQClickEvent } from '../../utils/metrics/listingsMetrics';

class Faqs {
  constructor() {
    $(window).on('load', this.offsetScroll);
    $(document).on('click', '.pc-Faqs-faq-question', this.toggleFaq);
  }

  // When fixed positioned toolbar is present, offset anchor scroll so that toolbar does not cover desired anchor location
  offsetScroll() {
    if (
      window.location.hash.indexOf('pc-Faq') === 1 &&
      $('.pc-ToolBar').length
    ) {
      const toolbarHeight = $('.pc-ToolBar').height();
      setTimeout(function () {
        window.scrollTo(window.scrollX, window.scrollY - toolbarHeight);
      }, 100);
    }
  }

  toggleFaq(e) {
    const el = $(e.target)
    trackFAQClickEvent(el.data('category-name'), !el.next().is(':visible'))

    el.next().slideToggle(200);
  }
}

ProductCatalog.Faqs = new Faqs();
