import Cookies from 'js-cookie';
class FullStoryIdentify {
  constructor() {
    this.getUserIdentity = this.getUserIdentity.bind(this);
    this.getProfilesUser = this.getProfilesUser.bind(this);
    this.getLabUserIdentity = this.getLabUserIdentity.bind(this);
    this.identify();
  }

  identify() {
    if (window.FS == null) {
      return;
    }
    const user = this.getUserIdentity();
    if (!user || !(user.length > 0)) {
      return;
    }
    return FS.identify(user);
  }

  getUserIdentity() {
    const userEmail = this.getProfilesUser();
    if (userEmail) {
      return userEmail;
    }
    return this.getLabUserIdentity();
  }

  getProfilesUser() {
    const profilesUser = Cookies.get('_profiles_user');
    if (profilesUser === 'none') {
      return;
    }
    if (profilesUser) {
      try {
        return JSON.parse(profilesUser).email;
      } catch (e) {}
    }
  }

  getLabUserIdentity() {
    const sbCookie = new StyleBitz.CustomInkCookie();
    const userIdentity = sbCookie.get('userid', {
      email: '',
      accountId: -1
    });
    if (userIdentity.email.length === 0) {
      return;
    }
    const s = userIdentity.email;
    let base64_s = '';
    let i = 0;
    while (i < s.length) {
      base64_s += String.fromCharCode(s.charCodeAt(i) - 5);
      i++;
    }
    return window.atob(base64_s);
  }
}

jQuery(() => (window.FullStoryIdentify = new FullStoryIdentify()));
