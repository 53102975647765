class LocalStorage {
  constructor() {
    this.isLocalStorageSupported = this.isLocalStorageSupported.bind(this);
    this.write = this.write.bind(this);
    this.read = this.read.bind(this);
    this.inMemoryStorage = {};
    this.localStorageSupported = this.isLocalStorageSupported();
  }

  // Check if localStorage is supported.
  // Older versions (including v10.x) of Safari doesn't support
  // localStorage in private browsing mode
  isLocalStorageSupported() {
    const testKey = 'pc_test_local_storage';
    try {
      localStorage.setItem(testKey, 'test');
      localStorage.removeItem(testKey);
      return true;
    } catch (e) {
      return false;
    }
  }

  write(key, value) {
    key = `pc.${key}`;
    if (this.localStorageSupported) {
      return localStorage.setItem(key, JSON.stringify(value));
    } else {
      return (this.inMemoryStorage[key] = value);
    }
  }

  read(key) {
    key = `pc.${key}`;
    if (this.localStorageSupported) {
      return JSON.parse(localStorage.getItem(key));
    } else {
      return this.inMemoryStorage[key];
    }
  }

  delete(key) {
    key = `pc.${key}`;
    if (this.localStorageSupported) {
      return localStorage.removeItem(key);
    } else {
      return delete this.inMemoryStorage[key];
    }
  }
}

ProductCatalog.LocalStorage = new LocalStorage();
