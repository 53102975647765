class Favorites {
  constructor() {
    this.toggleFavoriteFromStyles = this.toggleFavoriteFromStyles.bind(this);
    this.toggleFavoriteFromPDP = this.toggleFavoriteFromPDP.bind(this);

    $(document).on(
      'click',
      '.product-badge--favorite',
      this.toggleFavoriteFromStyles
    );
    $(document).on(
      'click',
      '.style-badge.style-badge--favorite',
      this.toggleFavoriteFromPDP
    );

    $(document).on('click', '.pc-ToolBar-favorite', this.toggleFavoriteFromPDP);

    if (window.location.pathname === '/products/favorites') {
      this.loadFavoriteCards();
    }

    this.favorites = ProductCatalog.LocalStorage.read('favorites') || [];
    this.setInitialState();
  }

  setInitialState() {
    if (this.favorites.length > 0) {
      const favoriteStyleIds = this.favoriteStyleIds();
      this.ensureFavoritePDPIsLabeled(favoriteStyleIds);
      this.ensureFavoriteCardsAreLabeled(favoriteStyleIds);
      this.updateToolBarFavoriteCount();
    }
  }

  favoriteStyleIds() {
    return ProductCatalog.LocalStorage.read('favorites') || [];
  }

  favoritedStyles(favoriteStyleIds) {
    return favoriteStyleIds.map(styleId => {
      return $(`.pc-ProductCard[data-style-id='${styleId}']`);
    });
  }

  updateToolBarFavoriteCount() {
    const favoritesLength = this.favorites.length
    if(favoritesLength > 0) {
      $('.pc-ToolBar-favorites-count').text(`(${this.favorites.length})`);
    } else {
      $('.pc-ToolBar-favorites-count').text('');
    }
  }

  ensureFavoritePDPIsLabeled(favoriteStyleIds = null) {
    const url = window.location.href.split('/');
    const styleId = parseInt(url[url.length - 1]);
    const badgeHolder = $('.pc-Style-hero-info .pc-Style-hero-badgeHolder');
    const toolBarHeader = $('.pc-ToolBar-header');

    if (badgeHolder.length > 0) {
      if (!favoriteStyleIds) {
        favoriteStyleIds = this.favoriteStyleIds();
      }
      if (favoriteStyleIds.indexOf(styleId) > -1) {
        const defaultFavorite = badgeHolder.find('.default')[0];
        if (!defaultFavorite) {
          toolBarHeader.find('.pc-ToolBar-favorite')[0].classList.add('added');
          return badgeHolder
            .find('.style-badge--favorite')[0]
            .classList.add('added');
        } else {
          toolBarHeader.find('.pc-ToolBar-favorite')[0].classList.add('added');
          defaultFavorite.classList.remove('default');
          return defaultFavorite.classList.add('added');
        }
      } else {
        const addedFavorite = badgeHolder.find('.added')[0];
        if (!addedFavorite) {
          toolBarHeader
            .find('.pc-ToolBar-favorite')[0]
            .classList.remove('added');
          return badgeHolder
            .find('.style-badge--favorite')[0]
            .classList.add('default');
        } else {
          toolBarHeader
            .find('.pc-ToolBar-favorite')[0]
            .classList.remove('added');
          addedFavorite.classList.remove('added');
          return addedFavorite.classList.add('default');
        }
      }
    }
  }

  ensureFavoriteCardsAreLabeled(favoriteStyleIds = null, removed = null) {
    if (!favoriteStyleIds) {
      favoriteStyleIds = this.favoriteStyleIds();
    }
    if (removed) {
      favoriteStyleIds.push(removed);
    }
    const favorites = this.favoritedStyles(favoriteStyleIds);
    return favorites.forEach((element, index) => {
      if (element.length > 0) {
        if (removed && index === favoriteStyleIds.length - 1) {
          if (window.location.pathname === '/products/favorites') {
            return element.css('display', 'none');
          } else {
            return element
              .find('.product-badge--favorite')[0]
              .classList.remove('added');
          }
        } else {
          return element
            .find('.product-badge--favorite')[0]
            .classList.add('added');
        }
      }
    });
  }

  toggleFavoriteFromPDP(evt) {
    const url = window.location.href.split('/');
    const styleId = parseInt(url[url.length - 1]);
    const index = this.favorites.indexOf(styleId);
    if (index > -1) {
      this.favorites.splice(index, 1);
    } else {
      this.favorites.push(styleId);
    }
    ProductCatalog.LocalStorage.write('favorites', this.favorites);
    this.updateToolBarFavoriteCount();
    this.ensureFavoritePDPIsLabeled();
  }

  getStyleIdOfToggle(evt) {
    if (
      $(evt.target)
        .parents('.pc-ProductCard')
        .data('style-id')
    ) {
      return $(evt.target)
        .parents('.pc-ProductCard')
        .data('style-id');
    }
  }

  toggleFavoriteFromStyles(evt) {
    evt.preventDefault();
    const styleId = parseInt(this.getStyleIdOfToggle(evt));
    if (styleId) {
      this.favorites = this.favoriteStyleIds();
      const index = this.favorites.indexOf(styleId);
      let removed;
      if (index > -1) {
        this.favorites.splice(index, 1);
        removed = styleId;
      } else {
        this.favorites.push(styleId);
      }
      evt.preventDefault();
      ProductCatalog.LocalStorage.write('favorites', this.favorites);
      this.updateToolBarFavoriteCount();
      this.ensureFavoriteCardsAreLabeled(this.favorites, removed);
      if (this.favoriteStyleIds().length < 1) {
        $('.favorites-empty')
          .css('display', 'flex')
          .css('justify-content', 'center');
      }
    }
  }

  loadFavoriteCards() {
    const favoriteStyleIds = this.favoriteStyleIds().join(',');
    if (this.favoriteStyleIds().length < 1) {
      $('.favorites-loader').hide();
      $('.favorites-empty')
        .css('display', 'flex')
        .css('justify-content', 'center');
    } else {
      $.ajax({
        url: `/products/custom?id=${favoriteStyleIds}&favorites=true`
      })
        .done(res => {
          $('.pc-Styles-products').html(res);
          $('.favorites-loader').hide();
          ProductCatalog.ImageLazyLoader.unobserveAll();
          ProductCatalog.ImageLazyLoader.observeAll();
          this.ensureFavoriteCardsAreLabeled()
        })
        .fail((xhr, status, error) => console.log(error));
    }
  }
}

jQuery(() => (ProductCatalog.Favorites = new Favorites()));
