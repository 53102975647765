class FullStory {
  constructor() {
    this.event = this.event.bind(this);
  }

  fsToString(action) {
    return `FS - ${action}:`;
  }

  safeFullStory(method) {
    if (window.FS && window.FS[method]) {
      return window.FS[method];
    }
    return () => {};    
  }

  event(action) {
    if(__ENV__ === 'production') {
      this.safeFullStory('event')(action, {})
    } else if(__ENV__ === 'test') {
      return {}
    } else {
      console.log(`${this.fsToString('Page Action')} ${action}`)
    }
  }
}
ProductCatalog.FullStory = new FullStory();
