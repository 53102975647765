class ImageLazyLoader {
  constructor() {
    this.imageObserve = this.imageObserve.bind(this);
    this.spinnerInsert = this.spinnerInsert.bind(this);
    this.observeAll = this.observeAll.bind(this);
    this.unobserveAll = this.unobserveAll.bind(this);
    this.lazyImageObserver = new IntersectionObserver(
      (entries, observer) => {
        return this.imageObserve(entries, observer);
      },
      {rootMargin: '0px 0px 100px 0px'}
    );
    this.observeAll();
  }

  imageObserve(entries, observer) {
    return entries.forEach((entry, i) => {
      if (entry.isIntersecting) {
        const $lazyImage = $(entry.target);
        $lazyImage.after(this.spinnerInsert());
        $lazyImage.attr('src', $lazyImage.data('src'));
        $lazyImage.attr('srcset', $lazyImage.data('srcset'));
        $lazyImage.removeClass('pc-lazyImage-isLoading');
        observer.unobserve($lazyImage[0]);
        return $lazyImage.one('load', this.spinnerRemove).each((idx, e) => {
          return this.triggerLoad(e);
        });
      }
    });
  }

  spinnerRemove(evt) {
    return $(evt.target).siblings('.pc-loadingIndicator').remove();
  }

  triggerLoad(e) {
    if (e.complete) {
      return $(e).trigger('load');
    }
  }

  spinnerInsert(el) {
    return (
      '<div class="pc-loadingIndicator" aria-hidden="true">' +
      '<div class="pc-loadingIndicator-spinner">Loading...</div></div>'
    );
  }

  observeAll() {
    return $('.pc-lazyImage-isLoading').each((idx, e) => {
      return this.lazyImageObserver.observe(e);
    });
  }

  unobserveAll() {
    return this.lazyImageObserver.disconnect();
  }
}

jQuery(() => (ProductCatalog.ImageLazyLoader = new ImageLazyLoader()));
