class Overlay {
  constructor() {
    this.hide = this.hide.bind(this);
    window.addEventListener('popstate', this.hide);
  }

  el() {
    return this.createElement();
  }

  show() {
    if (!this.visible) {
      this.visible = true;
      return this.showElement();
    }
  }

  hide() {
    if (this.visible) {
      this.hideElement();
      return (this.visible = false);
    }
  }

  loadPage(newUrl) {
    requestAnimationFrame(() => this.show());
    StyleBitz.Utils.delay(100, () => {
      const state = this.createState(newUrl);
      history.replaceState(state, state.title, window.location.href);
      return (window.location = newUrl);
    });
    return undefined;
  }

  createState(url) {
    return {pcOverlayShown: true, title: $('title').text(), url};
  }

  // Private

  createElement() {
    if ($('.pc-Overlay').length) {
      return $('.pc-Overlay');
    }
    const overlay = $(`\
    <div class="pc-Overlay">
      <div class="pc-Overlay-spinner" role="spinner">
        <div class="pc-Overlay-spinnerIcon"></div>
      </div>
    </div>\
    `);
    $(document.body).prepend(overlay);
    return overlay;
  }

  showElement() {
    return this.el().addClass('is-Visible');
  }

  hideElement() {
    return this.el().removeClass('is-Visible');
  }
}

jQuery(() => (ProductCatalog.Overlay = new Overlay()));
